import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PrevArrow from 'images/shared/PrevArrow';

const NonPublicCaseStudies = () => {
  const { allSanityCaseStudy: nonPublicCaseStudies } = useStaticQuery(graphql`
    query {
      allSanityCaseStudy(filter: { publicFacingCaseStudy: { eq: false } }) {
        totalCount
        edges {
          node {
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);

  return (
    <Container>
      <BackButton to="/admin/">
        <PrevArrow width="1.688rem" />
      </BackButton>
      <h1>Non-Public Facing Case Studies</h1>
      {nonPublicCaseStudies?.edges.length ? (
        <ul>
          {nonPublicCaseStudies.edges.map(({ node }, i) => (
            <li key={i}>
              <Link to={`/admin/non-public-case-studies/${node.slug.current}`}>{node.title}</Link>
            </li>
          ))}
        </ul>
      ) : (
        <p>All case studies are currently public.</p>
      )}
    </Container>
  );
};

const BackButton = styled(Link)`
  align-items: center;
  border-radius: 0.125rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-top: -0.75em;
  margin-bottom: 2rem;
  transition: 150ms ease-in-out;
  width: 3rem;

  &:hover {
    background-color: #000;
  }

  &:not(:hover) {
    background-color: var(--ax-gold);
  }

  &:active {
    background-color: #c1beac;
  }
`;

const Container = styled.div`
  background: var(--ax-beige);
  position: relative;
  padding: 2em;
  margin: 90px auto 0;
  max-width: 62.5rem;

  a {
    color: #1d1d1b;
    outline: none;
    text-transform: capitalize;
  }

  p {
    margin-bottom: 1em;
  }

  h1 {
    text-align: center;
    margin: 0 auto 1em;
  }

  ul {
    margin-left: 2em;
  }
`;

export default NonPublicCaseStudies;
